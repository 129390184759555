<template>
  <p class="landing-text"> Welcome Fellow Boaters! We're still building out the new site. Come check back soon!<br/><br/>
  </p>
</template>

<script>
export default {

}
</script>

<style>
.landing-text {
  font-family: 'Josefin Sans';
  color: hsl(231, 36%, 70%);
  line-height: 1.3;
  font-size: 16px;
  font-style: italic;
}

</style>