<template>
    <!-- <mobile-view id="mobile-view"/> -->
    <div class="wrapper" id="desktop-view">
    <div class="card">
      <Logo />
      <Welcome />
      <LandingText />
      <!-- <email-form /> -->
      <br/><br/><br/><br/>
  </div>

  <div>
    <hero-cover-img />
  </div>
  </div>
    
</template>

<script>
import Logo from './components/Logo.vue'
import Welcome from './components/Welcome.vue'
import LandingText from './components/LandingText.vue'
import HeroCoverImg from './components/HeroCoverImg.vue'
// import EmailForm from './components/EmailForm.vue'
// import MobileView from './components/MobileView.vue'
export default {
  name: 'App',

  components: {
    Logo,
    Welcome,
    LandingText,
    HeroCoverImg,
    // EmailForm,
    // MobileView,
  },
  
}
</script>

<style>
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
  font-size: 16px;
  background: gainsboro;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  height: 500px;
  margin: 10rem auto;
  box-shadow:0 0 10px rgba(52, 51, 51, 0.8); 
  overflow: hidden;
}

.card{
  background-image: linear-gradient(135deg, hsl(0, 0%, 100%), hsl(243, 85%, 95%));
  display: flex;
  flex-direction: column;
  padding: 5rem 3rem;
  min-width: 55%;
}

@media screen and (max-width: 700px){
  .card {
    padding: 8rem 1rem;
  }
}

#mobile-view {
  display:none;
}

.attribution {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.attribution a {
  color: hsl(228, 45%, 44%);
}
</style>
