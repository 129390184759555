<template>
  <div class="image-container">
  <img :src="HeroCoverImgNew" class="hero-img">
  </div>
</template>

<script>
import HeroCoverImgNew from  '../assets/images/hero-desktop_new.jpg'

export default {
name: 'Hero',
props: '',

data: function() {
    return{
        HeroCoverImgNew,
    }
}
}
</script>

<style>
.image-container {
  max-width: 400px;
  max-height: 100%;
  overflow: hidden;
}

.hero-img {
  height: 555px;
}
@media screen and (max-width: 700px){
  .image-container {
    max-width: 100px;
  }
}
@media screen and (max-width: 500px){
  .image-container {
    max-width: 50px;
  }
}
@media screen and (max-width: 400px){
  .image-container {
    max-width: 10px;
  }
}

</style>